var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vs-table',{attrs:{"search":"","stripe":"","border":"","description":"","sst":true,"data":_vm.table.data,"max-items":_vm.table.length,"total":_vm.table.total},on:{"search":_vm.handleSearch,"change-page":_vm.handleChangePage,"sort":_vm.handleSort},scopedSlots:_vm._u([{key:"default",fn:function({ data }){return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr,attrs:{"data":tr}},[_c('vs-td',{staticClass:"whitespace-normal"},[(
              _vm.status == 0 ||
              _vm.status == 2 ||
              _vm.$store.getters['user/hasPermissions']('edit')
            )?_c('feather-icon',{staticClass:"ml-2",attrs:{"title":"Edit","icon":"EditIcon","svgClasses":"w-5 h-5 hover:text-primary stroke-current"},on:{"click":function($event){$event.stopPropagation();return _vm.handleEdit(data[indextr].id)}}}):_vm._e(),(
              _vm.status == 1 ||
              _vm.status == 3 ||
              _vm.status == 4 ||
              _vm.status == 5 ||
              _vm.status == 6 ||
              _vm.status == 7 ||
              _vm.$store.getters['user/hasPermissions']('show')
            )?_c('feather-icon',{staticClass:"ml-2",attrs:{"title":"Show","icon":"EyeIcon","svgClasses":"w-5 h-5 hover:text-primary stroke-current"},on:{"click":function($event){$event.stopPropagation();return _vm.handleShow(data[indextr].id)}}}):_vm._e(),(
              _vm.status == 3 ||
              _vm.status == 0 ||
              _vm.status == 2 ||
              _vm.$store.getters['user/hasPermissions']('edit')
            )?_c('feather-icon',{staticClass:"ml-2",attrs:{"title":"Approve","icon":"CheckCircleIcon","svgClasses":"w-5 h-5 hover:text-primary stroke-current"},on:{"click":function($event){$event.stopPropagation();return _vm.handleApprove(data[indextr])}}}):_vm._e(),(
              _vm.status == 0 ||
              _vm.status == 2 ||
              _vm.status == 6 ||
              _vm.$store.getters['user/hasPermissions']('edit')
            )?_c('feather-icon',{staticClass:"ml-2",attrs:{"title":"Cancel","icon":"XCircleIcon","svgClasses":"w-5 h-5 hover:text-primary stroke-current"},on:{"click":function($event){$event.stopPropagation();return _vm.handleReject(data[indextr].id)}}}):_vm._e()],1),_c('vs-td',{attrs:{"data":data[indextr].code}},[_vm._v(_vm._s(data[indextr].code))]),_c('vs-td',{attrs:{"data":data[indextr].type}},[_vm._v(_vm._s(data[indextr].top_model))]),_c('vs-td',{attrs:{"data":data[indextr].date
              ? data[indextr].date
              : data[indextr].payment_term_name}},[_vm._v(_vm._s(data[indextr].date ? _vm.$options.filters.formatDate(data[indextr].date) : data[indextr].payment_term_name))]),_c('vs-td',{attrs:{"data":data[indextr].supplier_name}},[_vm._v(_vm._s(data[indextr].supplier_name ? data[indextr].supplier_name : "ALL SUPPLIER"))]),_c('vs-td',{attrs:{"data":data[indextr].valid_from}},[_vm._v(_vm._s(_vm._f("formatDate")(data[indextr].valid_from)))]),_c('vs-td',{attrs:{"data":data[indextr].valid_to}},[_vm._v(_vm._s(_vm._f("formatDate")(data[indextr].valid_to)))]),_c('vs-td',[(
              _vm.status == 4 || _vm.$store.getters['user/hasPermissions']('edit')
            )?_c('vs-switch',{attrs:{"color":"success"},on:{"change":function($event){return _vm.handleActive(data[indextr])}},model:{value:(data[indextr].is_active),callback:function ($$v) {_vm.$set(data[indextr], "is_active", $$v)},expression:"data[indextr].is_active"}}):_vm._e(),(
              _vm.status == 7 || _vm.$store.getters['user/hasPermissions']('edit')
            )?_c('vs-switch',{attrs:{"disabled":""},model:{value:(data[indextr].is_active),callback:function ($$v) {_vm.$set(data[indextr], "is_active", $$v)},expression:"data[indextr].is_active"}}):_vm._e()],1)],1)})}}])},[_c('template',{slot:"header"},[_c('vs-dropdown',{staticClass:"cursor-pointer",attrs:{"vs-trigger-click":""}},[_c('div',{staticClass:"p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(this.table.start)+" - "+_vm._s(this.table.end)+" of "+_vm._s(this.table.total))]),_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","svgClasses":"h-4 w-4"}})],1),_c('vs-dropdown-menu',_vm._l((_vm.table.limits),function(item){return _c('vs-dropdown-item',{key:item,on:{"click":function($event){return _vm.handleChangelength(item)}}},[_c('span',[_vm._v(_vm._s(item))])])}),1)],1)],1),_c('template',{slot:"thead"},[_c('vs-th',{attrs:{"width":"10%"}}),_c('vs-th',{attrs:{"sort-key":"code"}},[_vm._v("TOP Code/Name")]),_c('vs-th',{attrs:{"sort-key":"type"}},[_vm._v("TOP Model ")]),_c('vs-th',{attrs:{"sort-key":"customer category"}},[_vm._v("TOP Value ")]),_c('vs-th',{attrs:{"sort-key":"supplier_id"}},[_vm._v("Supplier")]),_c('vs-th',{attrs:{"sort-key":"valid_from"}},[_vm._v("Valid From")]),_c('vs-th',{attrs:{"sort-key":"valid_to"}},[_vm._v("Valid To")]),_c('vs-th',{attrs:{"sort-key":"is_active"}},[_vm._v("Status")])],1)],2),_c('vs-pagination',{staticStyle:{"padding-top":"5px"},attrs:{"total":_vm.table.totalPage},model:{value:(_vm.setPage),callback:function ($$v) {_vm.setPage=$$v},expression:"setPage"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }