<template>
  <div>
    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      @search="handleSearch"
      @change-page="handleChangePage"
      @sort="handleSort"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </template>
      <template slot="thead">
        <vs-th width="10%"></vs-th>
        <vs-th sort-key="code">TOP Code/Name</vs-th>
        <vs-th sort-key="type">TOP Model </vs-th>
        <vs-th sort-key="customer category">TOP Value </vs-th>
        <vs-th sort-key="supplier_id">Supplier</vs-th>
        <vs-th sort-key="valid_from">Valid From</vs-th>
        <vs-th sort-key="valid_to">Valid To</vs-th>
        <vs-th sort-key="is_active">Status</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td class="whitespace-normal">
            <feather-icon
              v-if="
                status == 0 ||
                status == 2 ||
                $store.getters['user/hasPermissions']('edit')
              "
              title="Edit"
              icon="EditIcon"
              svgClasses="w-5 h-5 hover:text-primary stroke-current"
              class="ml-2"
              @click.stop="handleEdit(data[indextr].id)"
            />
            <feather-icon
              v-if="
                status == 1 ||
                status == 3 ||
                status == 4 ||
                status == 5 ||
                status == 6 ||
                status == 7 ||
                $store.getters['user/hasPermissions']('show')
              "
              title="Show"
              icon="EyeIcon"
              svgClasses="w-5 h-5 hover:text-primary stroke-current"
              class="ml-2"
              @click.stop="handleShow(data[indextr].id)"
            />
            <feather-icon
              v-if="
                status == 3 ||
                status == 0 ||
                status == 2 ||
                $store.getters['user/hasPermissions']('edit')
              "
              title="Approve"
              icon="CheckCircleIcon"
              svgClasses="w-5 h-5 hover:text-primary stroke-current"
              class="ml-2"
              @click.stop="handleApprove(data[indextr])"
            />

            <feather-icon
              v-if="
                status == 0 ||
                status == 2 ||
                status == 6 ||
                $store.getters['user/hasPermissions']('edit')
              "
              title="Cancel"
              icon="XCircleIcon"
              svgClasses="w-5 h-5 hover:text-primary stroke-current"
              class="ml-2"
              @click.stop="handleReject(data[indextr].id)"
            />
          </vs-td>
          <vs-td :data="data[indextr].code">{{ data[indextr].code }}</vs-td>
          <vs-td :data="data[indextr].type">{{
            data[indextr].top_model
          }}</vs-td>
          <vs-td
            :data="
              data[indextr].date
                ? data[indextr].date
                : data[indextr].payment_term_name
            "
            >{{
              data[indextr].date
                ? $options.filters.formatDate(data[indextr].date)
                : data[indextr].payment_term_name
            }}</vs-td
          >
          <vs-td :data="data[indextr].supplier_name">{{
            data[indextr].supplier_name
              ? data[indextr].supplier_name
              : "ALL SUPPLIER"
          }}</vs-td>
          <vs-td :data="data[indextr].valid_from">{{
            data[indextr].valid_from | formatDate
          }}</vs-td>
          <vs-td :data="data[indextr].valid_to">{{
            data[indextr].valid_to | formatDate
          }}</vs-td>
          <vs-td>
            <vs-switch
              v-if="
                status == 4 || $store.getters['user/hasPermissions']('edit')
              "
              v-model="data[indextr].is_active"
              color="success"
              @change="handleActive(data[indextr])"
            />
            <vs-switch
              v-if="
                status == 7 || $store.getters['user/hasPermissions']('edit')
              "
              v-model="data[indextr].is_active"
              disabled
            />
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    baseUrl: {
      type: String,
    },
    title: {
      type: String,
    },
    action: {
      type: String,
    },
    status: {
      type: Number,
    },
    supplier: {
      type: Number,
    },
    topmodel: {
      type: String,
    },
    customerCategory: {
      type: Array,
    },
    isFilter: {
      type: Boolean,
    },
    validTo: {
      type: String,
    },
    dateCondition: {
      type: String,
    },
  },
  data() {
    return {
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      table: this.tableState(),
      mutableAction: "",
      dataApprove: {},
      dataforApproval: {},
      st: 0,
    };
  },
  methods: {
    tableState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "updated_at",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      if (active) {
        this.table.order = key;
        this.table.sort = active;
      } else {
        this.table.order = "ID";
        this.table.sort = "desc";
      }
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleActive(data) {
      console.log("RRRRR", data);
      this.$vs.loading();
      this.$http
        .post(this.baseUrl + "/active-inactive", {
          id: data.id,
          is_active: data.is_active,
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "The data was successfully updated",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.getData();
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.getData();
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },

    getData() {
      let cusCategory = [];
      if (this.customerCategory != null) {
        this.customerCategory.forEach((element) => {
          cusCategory.push(element.ID);
        });
      }

      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "/all", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            status: !isNaN(this.status) ? Number(this.status) : null,
            supplier_id: this.supplier ? Number(this.supplier) : null,
            top_model: this.topmodel,
            customer_category: cusCategory.length > 0 ? cusCategory : null,
            valid_to: this.validTo,
            date_condition: this.dateCondition,
          },
        })
        .then((resp) => {
          console.log(resp.data);
          if (resp.status == "success") {
            console.log(this.topmodel);
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            this.$emit("isFilter", false);
            this.$vs.loading.close();
          } else {
            console.log(resp.data);
            this.$vs.loading.close();
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch != 0) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    // handleDelete(value) {
    //   this.detail = false;
    //   this.id = value;
    //   this.$vs.dialog({
    //     type: "confirm",
    //     color: "danger",
    //     title: `Confirm`,
    //     text: "Please confirm to delete this data",
    //     accept: this.acceptDelete,
    //   });
    // },
    // acceptDelete() {
    //   this.$vs.loading();
    //   this.$http
    //     .delete(this.baseUrl + "/" + this.id)
    //     .then((resp) => {
    //       this.$vs.loading.close();
    //       if (resp.code == 200) {
    //         this.$vs.notify({
    //           color: "success",
    //           title: "Success",
    //           text: "The data was successfully deleted",
    //           position: "top-right",
    //           iconPack: "feather",
    //           icon: "icon-x-circle",
    //         });
    //       } else {
    //         this.$vs.notify({
    //           color: "danger",
    //           title: "Error",
    //           text: resp.message,
    //           position: "top-right",
    //           iconPack: "feather",
    //           icon: "icon-x-circle",
    //         });
    //       }
    //       this.getData();
    //     })
    //     .catch((error) => {
    //       this.$vs.loading.close();
    //       console.log(error);
    //     });
    // },
    handleClose() {
      this.$emit("action", "");
    },
    handleEdit(id) {
      this.$router.push({
        name: "product-festives-edit",
        params: { id: id },
      });
    },
    handleShow(id) {
      this.$router.push({
        name: "product-festives-show",
        params: { id: id },
      });
    },
    handleApprove(data) {
      this.dataforApproval = {};
      this.dataforApproval = data;
      this.$vs.loading();
      this.st = null;
      if (this.status == 0) {
        this.st = 1;
      } else if (this.status == 3) {
        this.st = 4;
      } else if (this.status == 2) {
        this.st = 1;
      }

      if (this.st == 4) {
        this.$http
          .get("/api/v1/master/productFestives/release", {
            params: {
              id: data.id,
              supplier_id: data.supplier_id,
            },
          })
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.dataApprove = resp.data.records;
              // console.log("langsung sini", this.dataApprove);
              if (this.dataApprove.ID != 0) {
                this.$vs.dialog({
                  type: "confirm",
                  color: "warning",
                  title: `Confirm`,
                  text: "This festive product already in realise, do you want to release it?",
                  accept: this.acceptApprove,
                });
              } else {
                // console.log("disini", this.dataApprove);

                this.acceptApprove();
              }
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          });
      } else {
        this.acceptApprove();
      }
    },

    acceptApprove() {
      this.$vs.loading();
      this.$http
        .post("/api/v1/master/productFestives/status", {
          id: this.dataforApproval.id,
          status: this.st,
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "The data was successfully approved",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.getData();
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.getData();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleReject(id) {
      this.$vs.loading();
      this.$http
        .post("/api/v1/master/productFestives/status", {
          id: id,
          status: 5,
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "The data was successfully rejected",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.getData();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
  },
  mounted() {
    this.$store.dispatch("user/getPermissions", "product-festives");
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(value).add(-7, "h").utc().local().format("DD MMM YYYY");
      }
    },
  },
  watch: {
    action(val) {
      this.getData();
      this.mutableAction = val;
    },
    isFilter(val) {
      if (val) {
        this.getData();
        this.$emit("isFilter", false);
      }
    },
  },
};
</script>
